
import { defineComponent, ref } from "vue";
import { ErrorMessage, Field, Form } from "vee-validate";
import { Actions } from "@/store/enums/StoreEnums";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import * as Yup from "yup";

import { useToast } from "vue-toastification";

// Import loading overlay
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
// end import loading overlay

export default defineComponent({
  name: "sign-in",
  components: {
    Field,
    Form,
    ErrorMessage,
    Loading, //loading overlay
  },
  setup() {
    const store = useStore();
    const router = useRouter();
    const toast = useToast();
    const submitButton = ref<HTMLElement | null>(null);

    // loading overlay setting
    const isLoading = ref(false);
    const fullPage = ref(true);
    const isColor = "#007BFF";
    const isBackgroundColor = "#A3A3A3";
    const isOpacity = 0.5;
    const showPassword = ref(false);
    // end loading overlay setting

    //Create form validation object
    const login = Yup.object().shape({
      username: Yup.string().required("Nama Pengguna atau Email Wajib Diisi"),
      password: Yup.string().min(4).required("Kata Sandi Wajib Diisi"),
    });

    //Form submit function
    const onSubmitLogin = (values) => {
      // Clear existing errors
      store.dispatch(Actions.LOGOUT);

      // Activate indicator
      submitButton.value?.setAttribute("data-kt-indicator", "on");

      // loading overlay tampil
      isLoading.value = true;

      store
        .dispatch(Actions.LOGIN, values)
        .then(() => {
          const roleAkses = JSON.parse(
            localStorage.getItem("user_account")!
          ).role;

          if (roleAkses === "SUPER_ADMIN") {
            router.push({ name: "superadmin-dashboard" });
          } else if (
            roleAkses === "MARKETING_LEADER" ||
            roleAkses === "MARKETING_MEMBER"
          ) {
            router.push({ name: "superadmin-dashboard" });
          } else if (
            roleAkses === "SALES_LEADER" ||
            roleAkses === "SALES_AREA"
          ) {
            router.push({ name: "superadmin-dashboard" });
          } else if (roleAkses === "ADMIN_CARD") {
            router.push({ name: "superadmin-dashboard" });
          } else if (
            roleAkses === "IMPLEMENTATOR_LEADER" ||
            roleAkses === "IMPLEMENTATOR_MEMBER"
          ) {
            router.push({ name: "superadmin-dashboard" });
          } else if (
            roleAkses === "ADMIN_LEGAL" ||
            roleAkses === "ADMIN_LEGAL"
          ) {
            router.push({ name: "superadmin-dashboard" });
          } else if (roleAkses === "ADMIN_SCHOOL") {
            router.push({ name: "sekolah-dashboard" });
          } else if (roleAkses === "ADMIN_FINANCE") {
            router.push({ name: "superadmin-dashboard" });
          } else if (roleAkses === "CS_LEADER") {
            router.push({ name: "superadmin-dashboard" });
          } else if (roleAkses === "CS_MEMBER") {
            router.push({ name: "superadmin-dashboard" });
          } else {
            localStorage.clear();
            toast.warning("Anda Tidak Mendapatkan Akses");
            router.push({ name: "sign-in" });
          }
          toast.success("Login Berhasil");

          // loading ditutup
          isLoading.value = false;
        })
        .catch((errors) => {
          toast.error(store.getters.getErrors);

          // loading ditutup
          isLoading.value = false;
        });
      submitButton.value?.removeAttribute("data-kt-indicator");
    };
    const showPassWord = () => {
      if (showPassword.value == false) {
        showPassword.value = true;
      } else {
        showPassword.value = false;
      }
    };

    return {
      onSubmitLogin,
      login,
      submitButton,
      showPassWord,
      showPassword,
      // loading overlay
      isLoading,
      fullPage,
      isColor,
      isBackgroundColor,
      isOpacity,
    };
  },
});
